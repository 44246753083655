import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./common";
import { addQueryDictToUrl } from "../utils";

export const providersApi = createApi({
  reducerPath: "providersApi",
  baseQuery: baseQuery(),
  tagTypes: ["Providers"],
  endpoints: (builder) => ({
    providersSearch: builder.query<ListResponse<any>, Record<string, any> | void>({
      query: (queryParams) => addQueryDictToUrl(`/api/providers-profiles/public/search`, queryParams),
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...(queryArgs || {}) };
        if (newQueryArgs.offset) {
          delete newQueryArgs.offset;
        }
        return newQueryArgs;
      },
      merge: (currentCache, newItems) => {
        // Mege by unique provider id
        const results = [...currentCache.results];
        newItems.results.forEach((item) => {
          if (!results.find((result) => result.provider_id === item.provider_id)) {
            results.push(item);
          }
        });

        if (currentCache.results) {
          return {
            ...currentCache,
            ...newItems,
            results,
          };
        }
        return newItems;
      },
      providesTags: ["Providers"],
    }),
  }),
});
export const { useProvidersSearchQuery, useLazyProvidersSearchQuery } = providersApi;
