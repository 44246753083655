export const detectClientLanguage = () => {
  const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  if (!userLocale) {
    return "en";
  }
  if (userLocale.indexOf("ro") == 0) {
    return "romanian";
  }
  return "english";
};
