import { PayloadAction } from "@reduxjs/toolkit";
import { put, select, takeLeading } from "redux-saga/effects";

import { getErrorDetail } from "@/core/api/utils";
import { providerApi } from "@/core/api/services/provider";

import { providerActions } from "./actions";
import { providerSelectors } from "./selectors";
import { Profile, ProfileSummary, ProviderStatus } from "./types";
import { callRTKQuery } from "../api/sagas";

function* onInitializeProvider(action: PayloadAction<ProfileSummary>): Generator {
  const summary = action.payload;
  try {
    yield put(providerActions.setStatus(ProviderStatus.LOADING));
    yield put(providerActions.setProfileSummary(summary));
    const profile: any = yield callRTKQuery(providerApi.endpoints.getProviderProfile.initiate(summary.provider_id));
    yield put(providerActions.setProfile(profile as Profile));
    yield put(providerActions.setStatus(ProviderStatus.CONFIGURED));
  } catch (err) {
    yield put(providerActions.setStatus(ProviderStatus.ERROR));
  }
}

function* onInitializeAutomated(): Generator {
  const status = yield select(providerSelectors.status);
  if (status != ProviderStatus.NA) {
    return;
  }
  try {
    yield put(providerActions.setStatus(ProviderStatus.LOADING));

    const summary: any = yield callRTKQuery(providerApi.endpoints.getOrAllocateProvider.initiate());
    yield put(providerActions.setProfileSummary(summary as ProfileSummary));
    const profile: any = yield callRTKQuery(providerApi.endpoints.getProviderProfile.initiate(summary.provider_id));
    yield put(providerActions.setProfile(profile as Profile));
    yield put(providerActions.setStatus(ProviderStatus.CONFIGURED));
  } catch (err) {
    console.error("Failed to fetch provider", err);
    if (getErrorDetail(err) == "unavailable_provider_for_allocation") {
      yield put(providerActions.setStatus(ProviderStatus.NO_AVAILABILITY));
    } else {
      yield put(providerActions.setStatus(ProviderStatus.ERROR));
    }
  }
}

export default function* sagas(): Generator {
  yield takeLeading(providerActions.initializeAutomated, onInitializeAutomated);
  yield takeLeading(providerActions.initializeProvider, onInitializeProvider);
}
