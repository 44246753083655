import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProvidersState } from "./types";

const getInitialState = (): ProvidersState => {
  return {};
};

export const providersReducerSlice = createSlice({
  name: "providers",
  initialState: getInitialState(),
  reducers: {},
});
