import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./common";

export const salesApi = createApi({
  reducerPath: "salesApi",
  baseQuery: baseQuery(),
  tagTypes: ["BillingInfo"],
  endpoints: (builder) => ({
    getTransactions: builder.query<ListResponse<any>, number | void>({
      query: (page = 1) => `/api/customers-sales/transactions?page=${page}`,
      providesTags: ["BillingInfo"],
    }),
    getTransactionConfirmationFile: builder.query<any, string>({
      query: (transaction_id) => `/api/customers-sales/transactions/${transaction_id}/confirmation-file`,
    }),
  }),
});
export const { useGetTransactionsQuery, useGetTransactionConfirmationFileQuery } = salesApi;
