import { createApi } from "@reduxjs/toolkit/query/react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

import { baseQuery } from "./common";
import { addQueryDictToUrl } from "../utils";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const bookingsApi = createApi({
  reducerPath: "bookingsApi",
  baseQuery: baseQuery(),
  tagTypes: ["Slots"],
  endpoints: (builder) => ({
    getSlots: builder.query<any, any>({
      query: (queryParams) => addQueryDictToUrl(`/api/providers-customers-bookings/customers/slots`, queryParams),
      providesTags: ["Slots"],
    }),
    rejectInvitation: builder.mutation<any, string>({
      query: (slot_id) => ({
        url: `/api/providers-customers-bookings/customers/slots/${slot_id}/reject`,
        method: "POST",
      }),
      invalidatesTags: ["Slots"],
    }),
    createSlotPaymentInfo: builder.mutation<any, string>({
      query: (slot_id) => ({
        url: `/api/providers-customers-bookings/customers/slots/${slot_id}/create-payment-info`,
        method: "POST",
      }),
    }),
  }),
});
export const { useGetSlotsQuery, useRejectInvitationMutation, useCreateSlotPaymentInfoMutation } = bookingsApi;
