import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./common";

export const providerApi = createApi({
  reducerPath: "providerApi",
  baseQuery: baseQuery(),
  tagTypes: ["Providers", "AllocatedProvider", "Provider"],
  endpoints: (builder) => ({
    // Allocation
    getOrAllocateProvider: builder.query<any, void>({
      query: () => `/api/providers-customers/customers/get-or-allocate`,
      providesTags: ["AllocatedProvider"],
    }),
    getAllocatedProvider: builder.query<any, void>({
      query: () => `/api/providers-customers/customers/get-allocated`,
      providesTags: ["AllocatedProvider"],
    }),
    replaceAllocatedProvider: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/providers-customers/customers/replace-provider`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AllocatedProvider"],
    }),
    findNewprovider: builder.query<any, void>({
      query: () => `/api/providers-customers/customers/find-new-provider`,
    }),
    getCanChangeProvider: builder.query<any, void>({
      query: () => `/api/providers-customers/customers/can-change`,
      providesTags: ["AllocatedProvider"],
    }),
    // Search - single
    getProviderProfile: builder.query<any, string>({
      query: (provider_id) => `/api/providers-profiles/public/search/${provider_id}`,
      providesTags: ["Provider"],
    }),
    getProviderBilling: builder.query<any, string>({
      query: (provider_id) => `/api/providers/customers/billing/${provider_id}`,
      providesTags: ["Provider"],
    }),
    getProviderRatings: builder.query<any, string>({
      query: (provider_id) => `/api/providers-ratings/customers/bookings?provider_id=${provider_id}`,
      providesTags: ["Provider"],
    }),
  }),
});
export const {
  useGetProviderBillingQuery,
  useGetAllocatedProviderQuery,
  useGetProviderRatingsQuery,
  useGetProviderProfileQuery,
  useGetOrAllocateProviderQuery,
  useGetCanChangeProviderQuery,
  useFindNewproviderQuery,
  useReplaceAllocatedProviderMutation,
} = providerApi;
