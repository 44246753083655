export interface AuthState {
  status: AuthStatus;
  user?: User;
  guest?: Guest;
  token?: Token;
  userSecurity?: UserSecurity;
  userPreference?: UserPreference;
}

export type User = {
  id: string;
  name: string;
  email: string;
  status: UserStatusType;
};

export type UserSecurity = {
  email: string;
};

export type UserPreference = {
  language?: string;
  timezone?: string;
};

export type Guest = {
  id: string;
  name: string;
};

export enum UserStatusType {
  ACTIVE = "active",
  PENDING_CONFIRMATION = "pending_confirmation",
  SUSPENDED = "suspended",
}

export enum TokenType {
  USER = "user",
  GUEST = "guest",
}

export type Token = {
  token: string;
  type: TokenType;
};

export enum AuthStatus {
  NA = "NA",
  READY = "READY",
  ERROR = "ERROR",
}
