export enum ChatStatus {
  NA = "NA",
  LOADING = "LOADING",
  CONFIGURED = "CONFIGURED",
  ERROR = "ERROR",
}

export interface ChatState {
  status: ChatStatus;
  config?: ChatConfig;
  unreadChannelMessagesCount?: number;
}

export type ChatConfig = {
  user_id: string;
  token: string;
  api_key: string;
  channel_id: string;
  members_list: string[];
};
