import { TIMEZONE_CHOICES } from "@/core/constants";
import { OnboardingQuestionType, OnboardingStepType, Step } from "./types";

// !FIXME - this needs to be moved to the Backend

export const getDefaultSteps = (): Step[] => {
  return [
    {
      id: "static",
      slug: "static",
      type: OnboardingStepType.STATIC,
      title: "Static page title",
      description: "This is a static page and you can put a title, description image and action name for the button.",
      image: "/global/images/couch-therapy.webp",
      action: {
        next: {
          title: "Începe chestionarul",
        },
      },
    },
    {
      id: "consent",
      slug: "consent",
      type: OnboardingStepType.CONSENT,
      title: "Consent page",
      description: "This is similar with static page but it has a checkbox for consent.",
      image: "/global/images/couch-therapy.webp",
      question: {
        required: true,
      },
      action: {
        next: {
          title: "Continue",
        },
      },
    },
    {
      id: "text",
      slug: "text",
      type: OnboardingStepType.QUESTION,
      title: "This is a text question",
      question: {
        type: OnboardingQuestionType.TEXT,
        label: "Text label",
        required: true,
        placeholder: "Text placeholder",
      },
    },
    {
      id: "select",
      slug: "select",
      type: OnboardingStepType.QUESTION,
      title: "This is a select question",
      question: {
        type: OnboardingQuestionType.SELECT,
        label: "This is a select question label",
        required: true,
        answers: [
          {
            label: "Alege o valoare",
            value: "",
          },
          {
            label: "Sub 18 ani",
            value: "under-18",
          },
          {
            label: "18-24 ani",
            value: "18-24",
          },
          {
            label: "25-34 ani",
            value: "25-34",
          },
          {
            label: "35-44 ani",
            value: "35-44",
          },
          {
            label: "45-54 ani",
            value: "45-54",
          },
          {
            label: "55-64 ani",
            value: "55-64",
          },
          {
            label: "65+ ani",
            value: "65+",
          },
        ],
      },
    },

    {
      id: "radio",
      slug: "radio",
      type: OnboardingStepType.QUESTION,
      title: "This is a radio question",
      description: "Radio question description",
      question: {
        type: OnboardingQuestionType.RADIO,
        required: true,
        answers: [
          {
            label: "Label 1",
            value: "value 1",
          },
          {
            label: "Label 2",
            value: "value 2",
          },
          {
            label: "Label 3",
            value: "value 3",
          },
        ],
      },
    },
    {
      id: "checkbox",
      slug: "checkbox",
      type: OnboardingStepType.QUESTION,
      title: "This is a checkbox question",
      description: "checkbox question description",
      question: {
        type: OnboardingQuestionType.CHECKBOX,
        required: true,
        answers: [
          {
            label: "Label 1",
            value: "value 1",
          },
          {
            label: "Label 2",
            value: "value 2",
          },
          {
            label: "Label 3",
            value: "value 3",
          },
        ],
      },
    },
    {
      id: "textarea",
      slug: "textarea",
      type: OnboardingStepType.QUESTION,
      title: "This is a textarea question",
      question: {
        type: OnboardingQuestionType.TEXTAREA,
        label: "Textarea label",
        required: true,
        placeholder: "Textarea placeholder",
      },
    },
    {
      id: "timezone",
      slug: "timezone",
      type: OnboardingStepType.TIMEZONE,
      title: "This is a TIMEZONE question",
      description: "TIMEZONE question description",
      question: {
        required: true,
        answers: TIMEZONE_CHOICES,
      },
      action: {
        next: {
          url: "/auth/register",
          title: "Inregistreaza-te",
        },
      },
    },
  ];
};
