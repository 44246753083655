import dynamic from "next/dynamic";
import Head from "next/head";
import { FC, PropsWithChildren, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { appActions } from "@/core/store/redux/app/actions";
import { appSelectors } from "@/core/store/redux/app/selectors";
import { AppStatus } from "@/core/store/redux/app/types";
import { siteSelectors } from "@/core/store/redux/site/selectors";
import { SiteStatus } from "@/core/store/redux/site/types";

import { BadDomain } from "./AppState/BadDomain";
import { LoadingErrorPage } from "./AppState/LoadingErrorPage";
import { AppLoading } from "./AppState/AppLoading";

const AppState: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const status = useSelector(appSelectors.appStatus);
  const siteStatus = useSelector(siteSelectors.siteStatus);

  useEffect(() => {
    if (status == AppStatus.READY_PARTIAL) {
      dispatch(appActions.initializeFull());
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  if (status == AppStatus.LOADING)
    return (
      <>
        <Head>
          <title>Loading ...</title>
        </Head>
        <AppLoading />;
      </>
    );
  if (siteStatus == SiteStatus.INVALID)
    return (
      <>
        <Head>
          <title>Invalid Domain</title>
        </Head>
        <BadDomain />
      </>
    );
  else if (status == AppStatus.READY_FULL || status == AppStatus.READY_PARTIAL) {
    // Render the children if the app is ready or partially ready
    return children;
  } else
    return (
      <>
        <Head>
          <title>Error Initializing</title>
        </Head>
        <LoadingErrorPage />
      </>
    );
};

export default AppState;
