import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./common";

export const agreementsApi = createApi({
  reducerPath: "agreementsApi",
  baseQuery: baseQuery(),
  tagTypes: ["Agreements"],
  endpoints: (builder) => ({
    getAgreements: builder.query<ListResponse<any>, number | void>({
      query: (page = 1) => `/api/providers-customers-agreements/customers/agreements?page=${page}`,
      providesTags: ["Agreements"],
    }),
    getAgreementsUnsignedCount: builder.query<any, void>({
      query: () => `/api/providers-customers-agreements/customers/agreements/unsigned-count`,
      providesTags: ["Agreements"],
    }),
    getAgreementDetails: builder.query<any, string>({
      query: (id) => `/api/providers-customers-agreements/customers/agreements/${id}`,
    }),
    getAgreementFile: builder.query<any, string>({
      query: (id) => `/api/providers-customers-agreements/customers/agreements/${id}/download`,
    }),
    signAgreement: builder.mutation<any, void>({
      query: (id) => ({
        url: `/api/providers-customers-agreements/customers/agreements/${id}/sign`,
        method: "POST",
      }),
      invalidatesTags: ["Agreements"],
    }),
  }),
});
export const {
  useGetAgreementsQuery,
  useGetAgreementsUnsignedCountQuery,
  useGetAgreementDetailsQuery,
  useSignAgreementMutation,
  useGetAgreementFileQuery,
} = agreementsApi;
