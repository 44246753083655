import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";

import { routerActions } from "./actions";
import { RouterSection } from "./types";

const sectionNames: RouterSection[] = [
  "chat",
  "bookings",
  "auth",
  "help",
  "onboarding",
  "account",
  "video",
  "providers",
  "provider",
  "pages",
  "actions",
];

function* onSetUrl(action: PayloadAction<string | undefined>): Generator {
  yield setSectionFromUrl(action.payload);
}

function* setSectionFromUrl(url?: string): Generator {
  if (!url) {
    yield put(routerActions.setSegment(undefined));
    return;
  }
  const section: RouterSection | undefined = sectionNames.find((section) => {
    if (url.indexOf(`/${section}`) === 0) {
      return section;
    }
  });
  // !FIXME - reimplement subsection matching
  let subSection: string | undefined;
  if (section == "account") {
    subSection = url.replace("/account/", "");
  } else if (section == "chat") {
    subSection = url.replace("/chat/", "");
  }
  yield put(
    routerActions.setSegment({
      section,
      subSection,
    }),
  );
}

export default function* sagas(): Generator {
  yield takeLatest(routerActions.setUrl, onSetUrl);
}
