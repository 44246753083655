import { ReduxState } from "@/store/types";

import { Profile, ProfileSummary, ProviderState, ProviderStatus } from "./types";

const self = (state: ReduxState): ProviderState => state.provider;
const status = (state: ReduxState): ProviderStatus => state.provider.status;
const profile = (state: ReduxState): Profile => state.provider.profile;
const profileSummary = (state: ReduxState): ProfileSummary => state.provider.profileSummary;

export const providerSelectors = {
  self,
  status,
  profile,
  profileSummary,
};
