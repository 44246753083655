import { createAction } from "@reduxjs/toolkit";

import { providerReducerSlice } from "./slices";
import { ProfileSummary } from "./types";

const initializeAutomated = createAction("provider/initializeAutomated");
const initializeProvider = createAction<ProfileSummary>("provider/initializeProvider");
const loadMore = createAction("provider/loadMore");

export const providerActions = {
  initializeAutomated,
  initializeProvider,
  loadMore,
  ...providerReducerSlice.actions,
};
