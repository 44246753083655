export const AUTH_USER_COOKIE_KEY = "__token";
export const AUTH_GUEST_COOKIE_KEY = "__gutoken";

export const LANGUAGE_CHOICE_DEFAULT = "en";
export const LANGUAGE_CHOICES = [
  {
    value: "ro",
    label: "Romana",
  },
  {
    value: "en",
    label: "Engleza",
  },
];

export const GENDER_CHOICES = [
  {
    value: "",
    label: "Choose a value...",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "male",
    label: "Male",
  },
  {
    value: "other",
    label: "Other",
  },
];
export const TIMEZONE_CHOICES = [
  {
    value: "Europe/Bucharest",
    label: "România",
    searchValues: "Romania Bucharest Bucuresti",
    frequent: true,
  },
  {
    value: "Europe/London",
    label: "United Kingdom",
    searchValues: "United Kingdom London England Great Britain",
    frequent: true,
  },
  {
    value: "Europe/Berlin",
    label: "Germany",
    searchValues: "Deutschland Berlin Germany",
    frequent: true,
  },
  {
    value: "Europe/Paris",
    label: "France",
    searchValues: "Paris France French",
    frequent: true,
  },
  {
    value: "Europe/Madrid",
    searchValues: "Spain Madrid Espana",
    label: "Spain",
  },
  {
    value: "Europe/Rome",
    label: "Italy",
  },
  {
    value: "Europe/Amsterdam",
    label: "Netherlands",
  },
  {
    value: "Europe/Brussels",
    label: "Belgium",
  },
  {
    value: "Europe/Vienna",
    label: "Austria",
  },
  {
    value: "Europe/Zurich",
    label: "Switzerland",
  },
  {
    value: "Europe/Stockholm",
    label: "Sweden",
  },
  {
    value: "Europe/Oslo",
    label: "Norway",
  },
  {
    value: "Europe/Copenhagen",
    label: "Denmark",
  },
];
