import { put, select, takeLatest } from "redux-saga/effects";
import { isClient } from "@/core/utils/nextjs";
import { areTimezonesDifferent, getClientTimezone } from "@/core/utils/date/formatting";
import { actionItemsActions } from "./actions";
import { settingsActions } from "../settings/actions";
import { settingsSelectors } from "../settings/selectors";

function* onInitialize(): Generator {}

function* checkDifferentTimezones(): Generator {
  if (isClient()) {
    const userTimezone = (yield select(settingsSelectors.timezone)) as string;
    const timezone = getClientTimezone();
    if (userTimezone && areTimezonesDifferent(userTimezone, timezone)) {
      yield put(actionItemsActions.setTimezoneMismatch(true));
      console.warn(`Timezone mismatch: ${userTimezone} !== ${timezone}`);
    } else {
      yield put(actionItemsActions.setTimezoneMismatch(false));
    }
  }
}

export default function* sagas(): Generator {
  yield takeLatest(actionItemsActions.initialize, onInitialize);

  yield takeLatest(settingsActions.setDefaultTimezone, checkDifferentTimezones);
  yield takeLatest(settingsActions.setTimezone, checkDifferentTimezones);
}
