import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";

import { getClientTimezone } from "@/core/utils/date/formatting";
import { detectClientLanguage } from "@/core/utils/language";
import { isClient } from "@/core/utils/nextjs";

import { settingsActions } from "./actions";
import { authActions } from "../auth/actions";
import { UserPreference } from "../auth/types";

function* onInitialize(): Generator {
  if (isClient()) {
    // read the client timezone
    const timezone = getClientTimezone();
    yield put(settingsActions.setDefaultTimezone(timezone));
    // read the client language
    const language = detectClientLanguage();
    yield put(settingsActions.setDefaultLanguage(language));
  }
}

function* onSetUserPreference(action: PayloadAction<UserPreference>): Generator {
  const userTimezone = action.payload.timezone;
  const userLanguage = action.payload.language;
  if (userLanguage) {
    yield put(settingsActions.setLanguage(userLanguage));
  }
  if (userTimezone) {
    yield put(settingsActions.setTimezone(userTimezone));
  }
}

export default function* sagas(): Generator {
  yield takeLatest(settingsActions.initialize, onInitialize);
  yield takeLatest(authActions.setUserPreference, onSetUserPreference);
}
