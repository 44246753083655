import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./common";

export const pagesApi = createApi({
  reducerPath: "pagesApi",
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getPage: builder.query<any, string>({
      query: (slug) => `/api/cms-pages/static-pages/${slug}`,
    }),
  }),
});
export const { useGetPageQuery } = pagesApi;
