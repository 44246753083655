import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Profile, ProfileSummary, ProviderState, ProviderStatus } from "./types";

const getInitialState = (): ProviderState => {
  return {
    status: ProviderStatus.NA,
    profile: undefined,
    profileSummary: undefined,
  };
};

export const providerReducerSlice = createSlice({
  name: "provider",
  initialState: getInitialState(),
  reducers: {
    setStatus(state, action: PayloadAction<ProviderStatus>) {
      state.status = action.payload;
    },
    setProfile(state, action: PayloadAction<Profile>) {
      state.profile = action.payload;
    },
    setProfileSummary(state, action: PayloadAction<ProfileSummary>) {
      state.profileSummary = action.payload;
    },
  },
});
