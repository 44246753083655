import { ReduxState } from "@/store/types";

import { ChatConfig, ChatStatus } from "./types";

const config = (state: ReduxState): ChatConfig => {
  return state.chat.config;
};

const status = (state: ReduxState): ChatStatus => {
  return state.chat.status;
};

const unreadChannelMessagesCount = (state: ReduxState): number | undefined => {
  return state.chat.unreadChannelMessagesCount;
};

export const chatSelectors = {
  status,
  config,
  unreadChannelMessagesCount,
};
